import request from "@/utils/request";
import { baseUrl } from "@/utils/config";
import qs from "qs";
// 获取创建课表
//192.168.3.195:8002/api/course/table
export function coursetable(data) {
  return request({
    url: baseUrl + "/course/table",
    method: "get",
    params: data,
  });
}
//创建课表
export function save(data) {
  return request({
    url: baseUrl + "/course/schedule/save",
    method: "post",
    data,
  });
}
//获取课表
export function Schedule(data) {
  return request({
    url: baseUrl + "/course/schedule",
    method: "get",
    params: data,
  });
}

//获取年级数据
export function getGrade(data) {
  return request({
    url: baseUrl + "/xx/getGrade",
    method: "get",
    params: data,
  });
}
//获取班级数据
export function Class(data) {
  return request({
    url: baseUrl + "/xx/getClassAll",
    method: "get",
    params: data,
  });
}
//添加备课
export function preparationSave(data) {
  return request({
    url: baseUrl + "/xx/preparationSave",
    method: "post",
    data,
  });
}
//获取资源
export function Resources(data) {
  return request({
    url: baseUrl + "/xx/getResources",
    method: "get",
    params: data,
  });
}
//获取项目数据
export function Projects(data) {
  return request({
    url: baseUrl + "/xx/getProject",
    method: "get",
    params: data,
  });
}
export function upload(data) {
  return request({
    url: baseUrl + "/xx/upload",
    method: "post",
    data,
  });
}
//编辑课表
export function kbedit(data) {
  console.log(data, "===========================data");
  return request({
    url: baseUrl + "/course/schedule/edit",
    method: "post",
    data: data,
  });
}
//获取资源包
export function ResourcesPkgs(data) {
  return request({
    url: baseUrl + "/xx/getResourcesPkgs",
    method: "get",
    params: data,
  });
}

//获取设备
export function Device(data) {
  return request({
    url: baseUrl + "/xx/getDevice",
    method: "get",
    params: data,
  });
}

//获取周数
export function courseweek(data) {
  return request({
    url: baseUrl + "/home/course",
    method: "get",
    params: data,
  });
}

//取消备课
export function cancellations(data) {
  return request({
    url: baseUrl + "/xx/cancellation/" + data,
    method: "get",
    params: data,
  });
}
//编辑课表
// export function kbedit(data) {
//   return request({
//     url: baseUrl + "/course/schedule/edit",
//     method: "post",
//     data
//   });
// }
